import React, { useRef, useState, useEffect } from 'react';
import { addDoc, collection, serverTimestamp, } from 'firebase/firestore';
import { getDownloadURL, getMetadata, ref, uploadBytes, getStorage, listAll, deleteObject } from 'firebase/storage';
import { db } from '../firebase/firebase';   
import '../assets/styles/DocumentLibrary.css';
import MenuBar from './MenuBar'; // Import the MenuBar component
import logo from '../assets/images/FCC_logo_new.jpg';
import icon from '../assets/images/file.svg';
import image1 from '../assets/images/YHTMI_Logo.jpg';
import image2 from '../assets/images/Eden_Connect_Logo.jpg';
import image3 from '../assets/images/FCC_logo_new.jpg';

const DocumentLibrary = () => {
  const [currentPath, setCurrentPath] = useState('documents/');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [title, setTitle] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const [folders, setFolders] = useState([]); // Store folders and files properly as an array
  const inputRef = useRef(null);
  const storage = getStorage();

  useEffect(() => {
    const fetchFoldersAndFiles = async () => {
      try {
        const storageRef = ref(storage, currentPath); // Reference to current path
        
        // Recursive function to fetch folder data
        async function getFolderData(folderRef) {
          const result = await listAll(folderRef); // List all folders and files in the current path
          
          // Fetch all files within this folder
          const filePromises = result.items.map(async (fileRef) => {
            const fileUrl = await getDownloadURL(fileRef);
            const metadata = await getMetadata(fileRef);
            return {
              name: fileRef.name,
              url: fileUrl,
              size: metadata.size, // File size
              lastModified: metadata.updated, // Last modified date
            };
          });

          // Fetch all subfolders
          const subfolderPromises = result.prefixes.map(async (subfolderRef) => {
            return await getFolderData(subfolderRef); // Recursively fetch data for subfolders
          });

          const files = await Promise.all(filePromises);
          const subfolders = await Promise.all(subfolderPromises);

          return {
            folderName: folderRef.name,
            files: files,
            subfolders: subfolders,
          };
        };

        // Fetch the main folder data
        const folderData = await getFolderData(storageRef);
        setFolders([folderData]); // Update state with folder data including subfolders
      } catch (error) {
        console.error('Error fetching folders and files:', error);
      }
    };

    fetchFoldersAndFiles();
  }, [currentPath]);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setTitle(e.target.files[0].name);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }
    const fileRef = ref(storage, `${currentPath}${selectedFile.name}`);
    try {
      await uploadBytes(fileRef, selectedFile);
      const fileURL = await getDownloadURL(fileRef);
      await addDoc(collection(db, 'documents', 'thumbnails', 'faithcc'), {
        title: selectedFile.name,
        url: fileURL,
        serverTime: serverTimestamp(),
        modifiedBy: 'Current User',
      });
      alert('File uploaded successfully!');
      setSelectedFile(null);
      setTitle('');
      inputRef.current.value = null;
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file. Please try again.');
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName) {
      alert('Please enter a folder name.');
      return;
    }
    const handleFolderClick = (path) => {
      setCurrentPath(`${path}/`);
    };
    const folderRef = ref(storage, `${currentPath}${newFolderName}/.keep`);
    try {
      const emptyBlob = new Blob([''], { type: 'text/plain' });
      await uploadBytes(folderRef, emptyBlob);
      alert('Folder created successfully!');
      setNewFolderName('');
    } catch (error) {
      console.error('Error creating folder:', error);
      alert('Error creating folder. Please try again.');
    }
  };

  const handleFolderClick = (path) => {
    setCurrentPath(`${path}/`);
  };

  const handleGoBack = () => {
    const pathSegments = currentPath.split('/').filter(Boolean);
    if (pathSegments.length > 1) {
      pathSegments.pop(); // Remove the last segment
      setCurrentPath(pathSegments.join('/') + '/');
    }
  };

  
  const handleFileSelect = (file) => {
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles.includes(file)) {
        // If the file is already selected, remove it from the selection
        return prevSelectedFiles.filter((selectedFile) => selectedFile !== file);
      } else {
        // If the file is not selected, add it to the selection
        return [...prevSelectedFiles, file];
      }
    });
  };
  const handleDeleteSelectedFiles = () => {
    const storage = getStorage();

    selectedFiles.forEach((file) => {
      const fileRef = ref(storage, `${currentPath}/${file.name}`); // Create reference to file
      
      deleteObject(fileRef)
      .then(() => {
        console.log(`File ${file.name} deleted successfully`);
        // Optionally, update the UI after deletion
        setFolders((prevFolders) => {
          if (!prevFolders) return []; // If undefined, return empty array

          return prevFolders.map((folder) => ({
            ...folder,
            files: folder.files?.filter((f) => f.name !== file.name) || [] // Ensure files exist, else use empty array
          }));
        });
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
      });
  })
  
    // Clear selected files after deletion
    setSelectedFiles([]);
  };
  return (
    <div className="document-library-container">
      <MenuBar />
      <header className="document-library-header flex items-center justify-between p-4 bg-gray-200 shadow-md">
      {/* Horizontal Boxes for Images */}
<div className="image-boxes-container">
  <div className="image-box">
    <img src={image1} alt="Image 1" className="box-image" />
  </div>
  <div className="image-box">
    <img src={image2} alt="Image 2" className="box-image" />
  </div>
  <div className="image-box">
    <img src={image3} alt="Image 3" className="box-image" />
  </div>
</div>
</header>
{/* Upload section in a flex row */}
        <div className="upload-section flex items-center justify-between p-4 bg-gray-100">
          <input
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            className="file-input hidden"
          />
          <button
            onClick={() => inputRef.current.click()}
            className="select-button bg-blue-500 text-white px-4 py-2 rounded"
          >
            Select File/Folder
          </button>
          <button
            onClick={handleUpload}
            className="upload-button bg-green-500 text-white px-4 py-2 rounded"
          >
            Upload
          </button>
          <input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder="New Folder Name"
            className="folder-input px-2 py-1 border rounded"
          />
          <button
            onClick={handleCreateFolder}
            className="create-folder-button bg-blue-600 text-white px-4 py-2 rounded"
          >
            
            Create Folder
          </button>
        </div>
      
      <div className="navigation-controls p-2">
        {currentPath !== 'documents/' && (
          <button
            onClick={handleGoBack}
            className="go-back-button bg-gray-500 text-white px-2 py-1 rounded"
          >
            Go Back
          </button>
        )}
        <span className="current-path text-gray-700 ml-4">{currentPath}</span>
      </div>
{/* Delete Button */}
<div className="delete-selected-section p-4">
      <button
        onClick={handleDeleteSelectedFiles}
        className="delete-selected-button bg-red-500 text-white px-4 py-2 rounded"
        disabled={selectedFiles.length === 0} // Disable button if no files are selected
      >
        Delete Selected Files
      </button>
    </div>

{/* Document table container */}
<div className="document-table-container mt-4 p-4 bg-white shadow-md rounded">
      {/* Loop over folders and display them */}
      {folders.map((folder, index) => (
        <div key={index} className="folder-section">
          <h2 className="folder-heading text-xl font-bold">{folder.folderName}</h2>
          <ul className="file-list mt-2 space-y-2">
            {folder.files.map((file, fileIndex) => (
              <li key={fileIndex} className="file-item flex items-center justify-between">
                {/* File Name */}
                <div className="file-details">
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    {file.name}
                  </a>
                  <p>Size: {file.size} bytes | Last Modified: {new Date(file.lastModified).toLocaleDateString()}</p>
                </div>

                {/* Select Checkbox */}
                <div className="select-checkbox">
                  <input
                    type="checkbox"
                    checked={selectedFiles.includes(file)}
                    onChange={() => handleFileSelect(file)}
                    className="file-checkbox"
                  />
   </div>
              </li>
            ))}
              
            {/* Subfolders Display */}
            {folder.subfolders.length > 0 && (
              <ul> {/* Nested list for subfolders */}
                {folder.subfolders.map((subfolder, subIndex) => (
                  <li key={subIndex} className="subfolder-item">
                    <span onClick={() => handleFolderClick(`${currentPath}${subfolder.folderName}`)}>
                      {subfolder.folderName}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </ul>
        </div>
      ))}
    </div>
  </div>
  );
};

export default DocumentLibrary;
