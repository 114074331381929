// src/components/Messages.js
import React, { useState } from 'react';
import '../assets/styles/Messages.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom';
import BestBible from 'best-bible';

const Messages = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [verseResult, setVerseResult] = useState('');

  const handleSearch = async () => {
    try {
      const bible = new BestBible(); // Initialize the BestBible instance
      const response = await bible.search(searchTerm); // Search for the verse
      if (response && response.length > 0) {
        setVerseResult(response[0].text);  // Display the first matching verse
      } else {
        setVerseResult('Verse not found.');
      }
    } catch (error) {
      console.error('Error fetching verse:', error);
      setVerseResult('Error fetching verse.');
    }
  };

  // Sample data for the weeks; you can adjust this to fetch data dynamically if needed
  const weeks = [
    { title: 'October 6th 2024(Rev. Courtney Allen Crump)', content: '“Letting Go”\n\nMark 10:17-31\nProper 23B\nJesus’s directive for the rich man to sell his possessions and give the money to the poor makes today’s gospel lesson one of the most controversial and perhaps most creatively interpreted passages in Christian history.\nMonastics point to this passage as the basis for their vow of poverty.  Others insist Jesus’ directive was only meant to apply to the rich man himself, or only to the very rich, which often is defined as anyone with more resources than we have. Still others argue that Jesus’ main concern here is “attachment” to wealth, not the possession of it.  Or that the story is meant to underline that salvation comes not from human piety but from God’s grace alone.  Each of these interpretations has merit, and yet none do full justice to the story.   '},
    { title: 'October 6th 2024', content: 'Michael Renninger, Pastor of St. Mary’s Catholic Church in Richmond, Virginia, admits that he’s probably not the best person to be giving marital advice, and yet the kind of commitment marriage calls for is the kind of commitment Christ calls us to, all of us, even Catholic priests. Renninger begins his sermon from Mark 10:2-16 by saying, “Getting married is easy,” having helped a number of couples do exactly that; “staying married takes work,” he adds, having watched some of those same marriages fail. Jesus’ words about divorce can seem harsh, but his willingness to accept us in all circumstances proves that nothing can separate us from the love of God, not even that.' },
    { title: 'September 29th 2024', content: 'Carla Pratt Keyes, Pastor of Ginter Park Presbyterian Church in Richmond, Virginia, takes a long and loving look at the practice of prayer as she reflects on the words of James 5:13-20, this Sunday’s epistle reading. How does prayer work? What does it do? How can I make it work for me? Keyes confesses her own inability to understand or control something as mysterious as prayer, but also admits there are times when it works, and even times when it works through, or because, of us: when we become the answer to someone else’s prayer. Push the play button and listen in on Carla’s thoughtful and moving journey through the wilderness of prayer.' },
    { title: 'September 22nd 2024', content: 'Amy Starr Redwine, Pastor of First Presbyterian Church in Richmond, Virginia, begins this Sunday’s sermon from Mark 9:30-37 with children’s questions about God. She says, “Asking questions is one of the ways we learn about the world and the people around us. And while children are notorious for asking questions, something tends to happen to many of us, if not all of us, as we grow and learn. We become hesitant to ask our questions. In today’s passage we hear the second of three so-called passion predications that Jesus makes in Mark’s gospel. The disciples must have been filled with questions. But they were confused, and afraid. And so in their fear, and anxiety, and uncertainty, they kept quiet. They didn’t ask Jesus what his prediction really meant.” But what if they had?' },
  ];

  return (
    <div className="messages-page-container p-6">

      
      {/* Menu Bar */}
      <nav className="menu-bar">
        <ul>
        <li><Link to="/">Home</Link></li>
          <li><Link to="/login">Document Library</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/messages">Messages</Link></li>
            </ul>
      </nav>
      <h1 className="text-3xl font-bold text-center mb-6">Weekly Sermons</h1>
       {/* Bible Search Section
       <div className="bible-search">
        <input
          type="text"
          placeholder="Search for a verse (e.g., John 3:16)"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>

        {verseResult && (
          <div className="verse-result">
            <p>{verseResult}</p>
          </div>
        )} */}
      <div className="weeks-container grid grid-cols-1 gap-4">
        {weeks.map((week, index) => (
          <div
            key={index} style={{ margin: '20px 0'}}
            className="week-section p-4 bg-white shadow-md rounded-lg"
          >
            <h2 className="text-xl font-semibold mb-2">{week.title}</h2>
            <p className="text-gray-700">{week.content.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}</p>
          </div>
          
        ))}
      </div>
    </div>
    // </div>
  );
};


export default Messages;
