const events = [
    {
      title: 'New Years Day',
      start: new Date(2025, 0, 1), // May 13, 2025, at 6:00 AM
      end: new Date(2025, 0, 1), // May 15, 2025, at 9:30 AM
      description: 'New years day',
      type: 'holiday',
    },
    {
      title: 'Human Rights Day',
      start: new Date(2025, 2, 21), // May 20, 2025, at 9:00 AM
      end: new Date(2025, 2, 21), // May 20, 2025, at 11:00 AM
      description: 'Human Rights Day',
      type: 'holiday',
    },
    {
        title: 'Good Friday',
        start: new Date(2025, 3, 18), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 3, 18), // May 20, 2025, at 11:00 AM
        description: 'Good Friday',
        type: 'holiday',
      },
      {
        title: 'Family Day',
        start: new Date(2025, 3, 21), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 3, 21), // May 20, 2025, at 11:00 AM
        description: 'Family day',
        type: 'holiday',
      },
      {
        title: 'Freedom Day',
        start: new Date(2025, 3, 27), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 3, 27), // May 20, 2025, at 11:00 AM
        description: 'Freedom Day',
        type: 'holiday',
      },
      {
        title: 'Public Holiday Freedom day OBSERVED',
        start: new Date(2025, 3, 28), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 3, 28), // May 20, 2025, at 11:00 AM
        description: 'Public Holiday Freedom day OBSERVED',
      },
      {
        title: 'Workers Day',
        start: new Date(2025, 4, 1), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 4, 1), // May 20, 2025, at 11:00 AM
        description: 'Workers Day',
        type: 'holiday',
    },
      {
        title: 'Youth Day',
        start: new Date(2025, 5, 16), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 5, 16), // May 20, 2025, at 11:00 AM
        description: 'Youth Day',
        type: 'holiday',
      },
      {
        title: 'National Women’s Day',
        start: new Date(2025, 7, 9), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 7, 9), // May 20, 2025, at 11:00 AM
        description: 'National Women’s Day',
        type: 'holiday',
      },
      {
        title: 'Heritage Day',
        start: new Date(2025, 8, 24), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 8, 24), // May 20, 2025, at 11:00 AM
        description: 'Heritage Day',
        type: 'holiday',
      },

      {
        title: 'Day of Reconciliation',
        start: new Date(2025, 11, 16), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 11, 16), // May 20, 2025, at 11:00 AM
        description: 'Day of Reconciliation',
        type: 'holiday',
      },
      {
        title: 'Christmas Day',
        start: new Date(2025, 11, 25), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 11, 25), // May 20, 2025, at 11:00 AM
        description: 'Christmas Day',
        type: 'holiday',
      },
      {
        title: 'Day of Goodwill',
        start: new Date(2025, 11, 26), // May 20, 2025, at 9:00 AM
        end: new Date(2025, 11, 26), // May 20, 2025, at 11:00 AM
        description: 'Day of GoodWill',
        type: 'holiday',
      },
      {
        title: 'KOGMIs 13th Year Anniversary Conference',
        start: new Date(2025, 2, 26), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 2, 30),
        description: 'KOGMI',
        type: 'event',
      },
      {
        title: 'CHURCH ANNIVERARY',
        start: new Date(2025, 2, 6), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 2, 9),
        description: 'FAITHCC 8THYEAR CELEBRATION',
        type: 'event',
    
      },
      {
        title: 'YOUNG ADULTS',
        start: new Date(2025, 5, 27), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 5, 29),
        description: 'YOUTH OUTREACH WEEKEND STILL BAY',
        type: 'event',
      },
      {
        title: 'WOMENS CONFERENCE',
        start: new Date(2025, 7, 8), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 7, 10),
        description: 'ANNUAL INNITIATIVE',
        type: 'event',
      },
      {
        title: 'KINGDOM ALIGNMENT',
        start: new Date(2025, 9, 24), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 9, 26),
        description: 'APOSTLE M BURKE',
        type: 'event',
      },
      {
        title: 'YOUTH & YOUNG ADULTS ANNUAL CONFERENCE',
        start: new Date(2025, 5, 4), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 5, 6),
        description: 'KOGMI',
        type: 'event',
      },
      {
        title: 'ANNUAL WOMENS CONFERENCE',
        start: new Date(2025, 7, 1), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 7, 3),
        description: 'KOGMI',
        type: 'event',
      },
      
      {
        title: 'ANNUAL WOMENS CONFERENCE',
        start: new Date(2025, 3, 25), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 3, 27),
        description: 'YAHWEHS HOLY TEMPLE MINISTRIES INTERNATIONAL',
        type: 'event',
      },
      {
        title: 'ANNUAL LEADERSHIP SUMMIT',
        start: new Date(2025, 4, 30), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 5, 1),
        description: 'YAHWEHS HOLY TEMPLE MINISTRIES INTERNATIONAL',
        type: 'event',
      },
  
      {
        title: '9TH ANNIVERSARY CONFERENCE',
        start: new Date(2025, 8, 25), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 8, 28),
        description: 'YAHWEHS HOLY TEMPLE MINISTRIES INTERNATIONAL',
        type: 'event',
      },
      {
        title: 'BONFIRE',
        start: new Date(2025, 11, 5), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 11, 5),
        description: 'YAHWEHS HOLY TEMPLE MINISTRIES INTERNATIONAL',
        type: 'event',
      },
      {
        title: 'PROPHETIC CONFERENCE',
        start: new Date(2025, 5, 13, 6,0,0), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 5, 15, 9,30),
        description: 'BAY CHRISTIANS CHURCH',
        type: 'event',
      },
      {
        title: 'WOMENS CONFERENCE',
        start: new Date(2025, 7, 15, 18,0,0), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 7, 15,),
        description: 'BAY CHRISTIANS CHURCH',
        type: 'event',
      },
      {
        title: 'WOMENS CONFERENCE',
        start: new Date(2025, 7, 16, 9,0,0), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 7, 16, 15,0,0),
        description: 'BAY CHRISTIANS CHURCH',
        type: 'event',
      },
      {
        title: '8th ANNIVERSARY CONFERENCE',
        start: new Date(2025, 10, 14, 18,0,0), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 10, 14, 21,0,0),
        description: 'BAY CHRISTIANS CHURCH',
        type: 'event',
      },
      {
        title: '8th ANNIVERSARY CONFERENCE',
        start: new Date(2025, 10, 16, 9,30,0), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 10, 16, 13,0,0),
        description: 'BAY CHRISTIANS CHURCH Sunday Celebrations',
        type: 'event',
      },
      {
        title: 'WOMENS BREAKFAST',
        start: new Date(2025, 7, 9), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 7, 9),
        description: 'KOGMI',
        type: 'event',
      },
      {
        title: 'ANNUAL WOMENS HIGH TEA',
        start: new Date(2025, 7, 30), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 7, 30),
        description: 'KOGMI',
        type: 'event',
      },
      {
        title: 'ANNUAL MENS CONFERENCE',
        start: new Date(2025, 8, 26), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 8, 28),
        description: 'KOGMI',
        type: 'event',
      },
      {
        title: 'ANNUAL WOD CAMP',
        start: new Date(2025, 9, 31), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 10, 2),
        description: 'KOGMI',
        type: 'event',
      },
      {
        title: 'ANNUAL MOC CHURCH',
        start: new Date(2025, 10, 28), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 10, 30),
        description: 'KOGMI',
      },
      {
        title: 'ANNUAL WOMENS HIGH TEA',
        start: new Date(2025, 7, 30), // Month is 0-indexed in JavaScript (March is 2)
        end: new Date(2025, 7, 30),
        description: 'KOGMI',
      },
      {
        title: ' CHURCH Anniversary',
        start: new Date(2025, 3, 14), // Month is 0-indexed in JavaScript (Dec is 11)
        end: new Date(2025, 3, 16),
        description: 'CHURCH Anniversary',
      },
        
      {
        title: 'Youth Outreach',
        start: new Date(2025, 5, 16), // Month is 0-indexed in JavaScript (October is 9)
        end: new Date(2025, 5, 16),
        description: 'Youth day/Youth Outreach',
      },
      {
        title: 'Womans conference',
        start: new Date(2025, 7, 9), // Month is 0-indexed in JavaScript (October is 9)
        end: new Date(2025, 7, 9),
        description: 'WOMANS CONFERENCE',
      },
      {
        title: 'MENS CONFERENCE',
        start: new Date(2025, 8, 6), // Month is 0-indexed in JavaScript (October is 9)
        end: new Date(2025, 8, 6),
        description: 'MENS CONFERENCE',
      },
      {
        title: 'CT MINISTERS CONFERENCE',
        start: new Date(2025, 10, 1), // Month is 0-indexed in JavaScript (October is 9)
        end: new Date(2025, 10, 1),
        description: 'CT MINISTERS CONFERENCE',
      },
      {
        title: 'YOUTH CONFERENCE',
        start: new Date(2025, 1, 28), // Month is 0-indexed in JavaScript (October is 9)
        end: new Date(2025, 2, 2),
        description: 'YOUTH CONFERENCE',
      },
      {
        title: 'WOMENS CONFERENCE',
        start: new Date(2025, 4, 23), // Month is 0-indexed in JavaScript (October is 9)
        end: new Date(2025, 4, 25),
        description: 'WOMENS CONFERENCE',
      },
      {
        title: 'ANNIVERSARY CONFERENCE',
        start: new Date(2025, 5, 13), // Month is 0-indexed in JavaScript (October is 9)
        end: new Date(2025, 5, 15),
        description: 'ANNIVERSARY CONFERENCE',
      },

  ];
  
  export default events;